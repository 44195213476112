import { render, staticRenderFns } from "./ProductCellSimple.vue?vue&type=template&id=63875dec&scoped=true&"
import script from "./ProductCellSimple.vue?vue&type=script&lang=js&"
export * from "./ProductCellSimple.vue?vue&type=script&lang=js&"
import style0 from "./ProductCellSimple.vue?vue&type=style&index=0&id=63875dec&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "63875dec",
  null
  
)

export default component.exports