<template>
  <div class="product-cell d-flex position-relative align-items-start">
    <img style="width: 60px" class="mr-1" :src="url" :alt="name">
    <div>
      <div style="font-size: 13px" class="font-weight-bold">{{ name }}</div>
      <div style="font-size: 12px">{{ vendorCode }}</div>
      <div style="font-size: 12px">{{ brand }}</div>
    </div>
  </div>
</template>

<script>
import {mapActions} from "vuex"
import {
  BFormCheckbox
} from 'bootstrap-vue'

export default {
  name: "ProductCellSelect",
  components: {
    BFormCheckbox,
  },
  props: {
    url: String,
    name: String,
    vendorCode: String,
    brand: String,
  },
  data() {
    return {}
  },
  methods: {
    openDetail() {
      this.$emit('openDetail');
    },
    ...mapActions('currentSupply', ['delProductFromSupply'])
  }
}

</script>

<style scoped>
.product-cell {
  width: 200px;
}
</style>