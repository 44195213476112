<template>
  <div class="order-status-container">
    <h3>
      Импорт СМВ по srid
    </h3>
    <div style="min-width: 320px">
      Вставте список srid товаров разделенные переносом строки или пробелом.
    </div>
    <b-form-textarea
        class="my-1"
        v-model="listSridStr"
        rows="15"
    />
    <div class="my-1">
      <b-button
          @click="handlerInputSRid"
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="outline-primary">
        <feather-icon
            icon="SaveIcon"
            class="mr-50"
        />
        <span class="align-middle">Сохранить</span>
      </b-button>

    </div>

  </div>
</template>

<script>
import {
  BButton,
  BCard,
  BCardText,
  BCol, BFormCheckbox, BFormGroup, BFormInput, BFormSelect,
  BModal,
  BPagination, BRow,
  BSpinner,
  BTab,
  BTable,
  BTabs,
  VBPopover
} from "bootstrap-vue";
import {
  BFormTextarea
} from 'bootstrap-vue';
import ProductCellSimple from "@/components/ProductCellSimple";
import OrderStatus from "@/components/procurement/OrderStatus";
import {mapActions} from "vuex";


export default {
  name: "PopUpSMV",
  data() {
    return {
      listSridStr: '',
      listSrid: []
    }
  },
  props: {},

  methods: {
    async handlerInputSRid() {
      if (this.listSridStr) {
        await this.sendSridASSelRansom({addSrid: this.listSridStr.split(/[\n, ]+/)});
        this.$emit('close');
      }
    },

    ...mapActions('orders', ["sendSridASSelRansom"])
  },

  directives: {
    'b-popover': VBPopover
  },
  computed: {},


  components: {
    BFormTextarea,
    BButton,
  },
}
</script>

<style scoped>


</style>