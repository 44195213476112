<template>
  <div class="order-status-container">
    <div class="d-flex">
      <div class="state">
        <feather-icon
            v-b-popover.hover.top="`Заказ не забрали`"
            v-if="orderStatus === 'отказ'"
            icon="XCircleIcon"
            size="18"
            stroke="#EA5455"
            class="order-status-icon"
        />

        <feather-icon
            v-b-popover.hover.top="`Заказ получен`"
            v-else-if="orderStatus === 'получен'"
            icon="CheckSquareIcon"
            size="18"
            stroke="#67A583"
        />
        <feather-icon
            v-b-popover.hover.top="`Заказ в пути`"
            v-else
            icon="TruckIcon"
            size="18"
            stroke="#678CED"
        />
      </div>
      <div class="mx-1">
        <div class="date-order">
          {{ getDate }}
        </div>
        <div class="time-order">
          {{ getTime }}
        </div>
      </div>
      <div class="self-ransom">
        <feather-icon
            v-b-popover.hover.top="`СМВ`"
            v-if="selfRansom === 1"
            icon="RepeatIcon"
            size="18"
            stroke="#7367F0"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {VBPopover} from "bootstrap-vue";

export default {
  name: "OrderStatus",
  data() {
    return {}
  },
  props: {
    orderStatus: String,
    date: String,
    selfRansom: Number
  },

  directives: {
    'b-popover': VBPopover
  },
  computed: {
    getDate() {
      if (this.date) {
        let date = new Date(this.date);
        let year = date.getFullYear();
        let month = String(date.getMonth() + 1).padStart(2, '0');
        let day = String(date.getDate()).padStart(2, '0');
        return `${day}.${month}.${year}`;
      }
      return ''
    },
    getTime() {
      if (this.date) {
        let date = new Date(this.date);
        let hours = String(date.getHours()).padStart(2, '0');
        let min = String(date.getMinutes()).padStart(2, '0');
        return `${hours}:${min}`;
      }
      return ''
    }
  }
}
</script>

<style scoped>

.date-order {
  text-align: center;
}

.time-order {
  text-align: center;
}

.order-status-icon {

}

</style>